import React from 'react'

import styles from './Components.module.sass'

export const InstagramFeed = () => {
    return (
        <iframe
            src={'https://www.instagram.com/p/Cb_qhF8FX2r/embed'}
            className={styles.ig}
        />
    )
}
