import React from 'react'
import { Image } from 'react-bootstrap'

import styles from './Components.module.sass'

interface ProductProps {
	title: string
	url: string
	image: string
}

export const Product = (props: ProductProps) => {
	return (
		<a href={props.url} className={styles.product}>
			<div className={styles.title}>{props.title}</div>
			<Image src={props.image} alt={props.title} className={styles.image} />
			<div className={styles.overlay} />
		</a>
	)
}
