export const ICON_HEIGHT = 50
export const ICON_WIDTH = 50

export interface ColorIconProps {
	size?: number | string
	height?: number | string
	width?: number | string
	className?: string
}

export interface IconProps extends ColorIconProps {
	fill: string
}

export * from './AmazonIcon'
