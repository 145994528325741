import * as React from 'react'
import { Navbar, Container, Nav, Image, Row, Col } from 'react-bootstrap'
import { HIDEAWAYS_LOGO, COVERS_CREAM, COVERS_MOCHA, CIRCLES } from '../assets'
import { Divider, Product, InstagramFeed } from '../components'
import { AmazonIcon } from '../icons'

import styles from './Main.module.sass'

const URLS = {
	INSTAGRAM: 'https://instagram.com/gethideaways',
	AMAZON: 'http://www.amazon.com/dp/B09N7QYF3X',
	EMAIL: 'mailto:hideaways@paraworks.io',
	PRODUCTS: {
		COVERS_CREAM: 'https://www.amazon.com/dp/B09N7QRLPM?ref=gethideaways_com',
		COVERS_MOCHA: 'https://www.amazon.com/dp/B09N7QYF3X?ref=gethideaways_com',
	},
}

export const Main = () => {
	const [year] = React.useState(new Date().getFullYear())
	return (
		<Container id="main" className={styles.main}>
			<Navbar expand="lg" className={'p-0'}>
				<Navbar.Brand className={styles.navLogo} href="/">
					<Image src={HIDEAWAYS_LOGO} alt={'hideaways™️'} className={styles.logo} />
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
					<Nav className={styles.title}>
						<Nav.Link href={URLS.INSTAGRAM} className={styles.navLink}>
							instagram
						</Nav.Link>
						<Nav.Link href={URLS.AMAZON} className={styles.navLink}>
							shop on amazon.com
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
			<Row className={styles.splash}>
				<Image src={CIRCLES} alt={'hideaways™️ Circles'} className={styles.circles} />
				<div className={styles.splashInner}>
					<h3>affordable, high quality products to keep you covered</h3>
					<h6 className={'mono mt-2'}>shop on Amazon.com and leave us a review</h6>
					<a href={URLS.AMAZON} className={styles.shopNow}>
						<div className={styles.shopNowInner}>
							<AmazonIcon fill={'white'} size={16} />
							<div className={styles.shopNowText}>Shop Now</div>
						</div>
					</a>
				</div>
			</Row>
			<Divider />
			<Container className={styles.products} fluid>
				<h4 className={'title mt-4 mb-4 p-0'}>our products</h4>
				<Row className={'p-0 m-0'}>
					<Col className={'p-0 pr-2 pt-2'} xs={12} sm={6} md={4}>
						<Product
							title={'Adhesive Nipple Covers – Cream'}
							url={URLS.PRODUCTS.COVERS_CREAM}
							image={COVERS_CREAM}
						/>
					</Col>
					<Col className={'p-0 pr-2 pt-2'} xs={12} sm={6} md={4}>
						<Product
							title={'Adhesive Nipple Covers – Mocha'}
							url={URLS.PRODUCTS.COVERS_MOCHA}
							image={COVERS_MOCHA}
						/>
					</Col>
				</Row>
			</Container>
			<Divider />
			<Container className={styles.products} fluid>
				<h4 className={'title mt-4 mb-4 p-0'}>our ig</h4>
				<Row className={'p-0 m-0'}>
					<Col className={'p-0 pr-2 pt-2'} xs={12} sm={6} md={4}>
						<InstagramFeed />
					</Col>
				</Row>
			</Container>
			<Divider />
			<Container className={styles.footer} fluid>
				<Row className={'p-0 m-0 align-items-center'}>
					<Col xs={6} className={`${styles.smallFont} ${styles.footerLeft}`}>
						<div className={'d-none d-sm-block'}>Designed in Brooklyn</div>
						<div className={'d-none d-sm-block'}>Exclusive to Amazon.com</div>
						<div>
							Contact us at <a href={URLS.EMAIL}>hideaways@paraworks.io</a>.
						</div>
					</Col>
					<Col xs={6} className={styles.footerRight}>
						<Image src={HIDEAWAYS_LOGO} alt={'hideaways™️'} className={styles.logo} />
						<div className={`mt-2 text-right ${styles.smallFont}`}>&copy; Paraworks LLC {year}</div>
					</Col>
				</Row>
			</Container>
		</Container>
	)
}
