// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyDJ2w1J_JO1DGGj9Bx05V1s66vU6ExpBLc',
    authDomain: 'gethideaways.firebaseapp.com',
    projectId: 'gethideaways',
    storageBucket: 'gethideaways.appspot.com',
    messagingSenderId: '114175816654',
    appId: '1:114175816654:web:97203d82395efc4b01b19f',
    measurementId: 'G-HWYZKF5ZJT'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

export const analytics = getAnalytics(app)
